import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = ["value", "onChange"]
const _hoisted_2 = ["value", "onChange"]
const _hoisted_3 = ["value", "onChange"]
const _hoisted_4 = ["value", "onChange"]
const _hoisted_5 = ["value", "onChange"]
const _hoisted_6 = ["value", "onChange"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Button, {
      class: "cancel-button",
      onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.cancel()), ["prevent"]))
    }, {
      default: _withCtx(() => _cache[7] || (_cache[7] = [
        _createTextVNode("Zurück")
      ])),
      _: 1
    }),
    _cache[14] || (_cache[14] = _createElementVNode("h1", null, "Einstellungen", -1)),
    _createElementVNode("div", null, [
      _cache[8] || (_cache[8] = _createElementVNode("div", null, "Diese Lohnarten werden zur Berechnung vom Lohn ATP verwendet:", -1)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lohnarten, (lohnart) => {
        return (_openBlock(), _createElementBlock("p", { key: lohnart }, [
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.lohnCheckerATP) = $event)),
            type: "checkbox",
            value: lohnart,
            onChange: ($event: any) => (_ctx.save(lohnart, $event, 'Lohn ATP'))
          }, null, 40, _hoisted_1), [
            [_vModelCheckbox, _ctx.lohnCheckerATP]
          ]),
          _createElementVNode("label", null, _toDisplayString(lohnart), 1)
        ]))
      }), 128))
    ]),
    _cache[15] || (_cache[15] = _createElementVNode("br", null, null, -1)),
    _createElementVNode("div", null, [
      _cache[9] || (_cache[9] = _createElementVNode("div", null, "Diese Lohnarten werden zur Berechnung vom Lohn WP verwendet::", -1)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lohnarten, (lohnart) => {
        return (_openBlock(), _createElementBlock("p", { key: lohnart }, [
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.lohnCheckerWP) = $event)),
            type: "checkbox",
            value: lohnart,
            onChange: ($event: any) => (_ctx.save(lohnart, $event, 'Lohn WP'))
          }, null, 40, _hoisted_2), [
            [_vModelCheckbox, _ctx.lohnCheckerWP]
          ]),
          _createElementVNode("label", null, _toDisplayString(lohnart), 1)
        ]))
      }), 128))
    ]),
    _cache[16] || (_cache[16] = _createElementVNode("br", null, null, -1)),
    _createElementVNode("div", null, [
      _cache[10] || (_cache[10] = _createElementVNode("div", null, " Diese Lohnarten werden zur Berechnung vom Lohn Quali verwendet:: ", -1)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lohnarten, (lohnart) => {
        return (_openBlock(), _createElementBlock("p", { key: lohnart }, [
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.lohnCheckerQuali) = $event)),
            type: "checkbox",
            value: lohnart,
            onChange: ($event: any) => (_ctx.save(lohnart, $event, 'Lohn Quali'))
          }, null, 40, _hoisted_3), [
            [_vModelCheckbox, _ctx.lohnCheckerQuali]
          ]),
          _createElementVNode("label", null, _toDisplayString(lohnart), 1)
        ]))
      }), 128))
    ]),
    _cache[17] || (_cache[17] = _createElementVNode("br", null, null, -1)),
    _createElementVNode("div", null, [
      _cache[11] || (_cache[11] = _createElementVNode("div", null, " Diese Lohnarten werden zur Berechnung der Sozialleistung ATP verwendet: ", -1)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lohnarten, (lohnart) => {
        return (_openBlock(), _createElementBlock("p", { key: lohnart }, [
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.sozialCheckerATP) = $event)),
            type: "checkbox",
            value: lohnart,
            onChange: ($event: any) => (_ctx.save(lohnart, $event, 'Sozial ATP'))
          }, null, 40, _hoisted_4), [
            [_vModelCheckbox, _ctx.sozialCheckerATP]
          ]),
          _createElementVNode("label", null, _toDisplayString(lohnart), 1)
        ]))
      }), 128))
    ]),
    _cache[18] || (_cache[18] = _createElementVNode("br", null, null, -1)),
    _createElementVNode("div", null, [
      _cache[12] || (_cache[12] = _createElementVNode("div", null, " Diese Lohnarten werden zur Berechnung der Sozialleistung WP verwendet: ", -1)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lohnarten, (lohnart) => {
        return (_openBlock(), _createElementBlock("p", { key: lohnart }, [
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.sozialCheckerWP) = $event)),
            type: "checkbox",
            value: lohnart,
            onChange: ($event: any) => (_ctx.save(lohnart, $event, 'Sozial WP'))
          }, null, 40, _hoisted_5), [
            [_vModelCheckbox, _ctx.sozialCheckerWP]
          ]),
          _createElementVNode("label", null, _toDisplayString(lohnart), 1)
        ]))
      }), 128))
    ]),
    _cache[19] || (_cache[19] = _createElementVNode("br", null, null, -1)),
    _createElementVNode("div", null, [
      _cache[13] || (_cache[13] = _createElementVNode("div", null, " Diese Lohnarten werden zur Berechnung der Sozialleistung Quali verwendet: ", -1)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lohnarten, (lohnart) => {
        return (_openBlock(), _createElementBlock("p", { key: lohnart }, [
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.sozialCheckerQuali) = $event)),
            type: "checkbox",
            value: lohnart,
            onChange: ($event: any) => (_ctx.save(lohnart, $event, 'Sozial Quali'))
          }, null, 40, _hoisted_6), [
            [_vModelCheckbox, _ctx.sozialCheckerQuali]
          ]),
          _createElementVNode("label", null, _toDisplayString(lohnart), 1)
        ]))
      }), 128))
    ])
  ]))
}